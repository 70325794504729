
const color = {
  blue: "#181344",
  red: "#ED1C24",
  black: "#000",
  gray: "#606060",
  white: "#ffffff"
};


export default color;